html {
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
	width: 100%;
  height: 100%;
	
	color:#3B3B3B;
}

h2 {
	font-weight: 300;
	font-size: 25px;
}

h2 strong {
	font-weight: 900;
}

h3 {
	color: #fff;
	font-size: 14px;
	font-weight: 900;
}

@media (min-width: 768px) and (max-width: 1199.98px) { h3 {font-size: 2.083vw;} }

@media (min-width: 1200px) { 

	h3 {font-size: 25px;}
}

p { font-weight: 300;
	font-size: 14px;
}

p strong {
	font-weight: 900;
	color: #D81C57;
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.content {
	padding: 10px 30px;
}

.logo {
	width: 50%;
	height: auto;
	max-width: 241px;
}

.buttons {
	max-width: 320px;
	margin: auto;
}

.btn {
	text-align: left;
	font-weight: 300;
	font-size: 25px;
	border-radius: 5px;
	
	background-image: url("../img/arrow.svg");
	background-repeat: no-repeat;
	background-position: center right 12px;
	
	border: 0;
	
	-webkit-box-shadow: 0px 1px 2px 0px rgba(59,59,59,1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(59,59,59,1);
    box-shadow: 0px 1px 2px 0px rgba(59,59,59,1);
	margin-bottom: 15px;
    }
.btn strong {
	font-weight: 900;
}

.btn:hover {
	background-color: #D81C57;
}

.color-1 {background-color: #BED0D6;}
.color-2 {background-color: #C2875F;}
.color-3 {background-color: #93C864;}
.color-4 {background-color: #D81C57;}
.color-5 {background-color: #FFC550;}
.color-6 {background-color: #39AFCE;}

.color-1:hover {background-color: #7EC1D6;}
.color-2:hover {background-color: #C26325;}
.color-3:hover {background-color: #73C828;}
.color-4:hover {background-color: #D80044;}
.color-5:hover {background-color: #FFAB03;}
.color-6:hover {background-color: #00A3CE;}

.bestseller {
	position: relative;
	z-index: 2;
	margin-bottom: 25px;
}

.bestseller::before {
    content: '';
	display: block;
	position: absolute;
	left: -6px;
	right: -6px;
	bottom: -6px;
	top: -6px;
	border: solid 2px #D81C57;
	border-radius: 7px;
}

.bestseller::after {
    content: 'Bestseller';
	display: block;
	position: absolute;
	font-size: 13px;
	line-height: 1.2;
	font-weight: 700;
	background-color:#D81C57; 
	width: auto;
	left: auto;
	right:0;
	bottom: -20px;
	top: auto;
	border-radius: 0 0 5px 5px;
	padding-left: 5px;
	padding-right: 5px;
}


.carousel-inner {
	height: 300px;
	width: 100%;
}

@media (min-width: 768px) { 

.carousel-inner {
	position: fixed;
	top:0;
	left:0;
	right: 50%;
	height: 100vh;
	width: 50vw;
}

}

.carousel-item {
	width: 100%;
	height: 100%;
	position: absolute;

	background-position: center top;
	background-size: cover;
}

.carousel-item.bg1 {
	background-image: url("../img/foto1.jpg")
}
.carousel-item.bg2 {
	background-image: url("../img/foto2.jpg")
}
.carousel-item.bg3 {
	background-image: url("../img/foto3.jpg")
}
.carousel-item.bg4 {
	background-image: url("../img/foto4.jpg")
}

.carousel-caption {
	padding: 15px;
	left: 0;
	right: 0;
	bottom: 0;

}

.carousel-item.bg1 .carousel-caption {

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9d1f62+0,9d1f62+100&0+0,0+25,1+75,1+100 */
background: -moz-linear-gradient(top,  rgba(157,31,98,0) 0%, rgba(157,31,98,0) 25%, rgba(157,31,98,1) 75%, rgba(157,31,98,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(157,31,98,0) 0%,rgba(157,31,98,0) 25%,rgba(157,31,98,1) 75%,rgba(157,31,98,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(157,31,98,0) 0%,rgba(157,31,98,0) 25%,rgba(157,31,98,1) 75%,rgba(157,31,98,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009d1f62', endColorstr='#9d1f62',GradientType=0 ); /* IE6-9 */

}
.carousel-item.bg2 .carousel-caption {
	
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fb3862+0,fb3862+100&0+0,0+25,1+75,1+100 */
background: -moz-linear-gradient(top,  rgba(251,56,98,0) 0%, rgba(251,56,98,0) 25%, rgba(251,56,98,1) 75%, rgba(251,56,98,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(251,56,98,0) 0%,rgba(251,56,98,0) 25%,rgba(251,56,98,1) 75%,rgba(251,56,98,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(251,56,98,0) 0%,rgba(251,56,98,0) 25%,rgba(251,56,98,1) 75%,rgba(251,56,98,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fb3862', endColorstr='#fb3862',GradientType=0 ); /* IE6-9 */

}
.carousel-item.bg3 .carousel-caption {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4c5d7+0,b4c5d7+100&0+0,0+25,1+75,1+100 */
background: -moz-linear-gradient(top,  rgba(180,197,215,0) 0%, rgba(180,197,215,0) 25%, rgba(180,197,215,1) 75%, rgba(180,197,215,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(180,197,215,0) 0%,rgba(180,197,215,0) 25%,rgba(180,197,215,1) 75%,rgba(180,197,215,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(180,197,215,0) 0%,rgba(180,197,215,0) 25%,rgba(180,197,215,1) 75%,rgba(180,197,215,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b4c5d7', endColorstr='#b4c5d7',GradientType=0 ); /* IE6-9 */

}
.carousel-item.bg4 .carousel-caption {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdb42d+0,fdb42d+100&0+0,0+25,1+75,1+100 */
background: -moz-linear-gradient(top,  rgba(253,180,45,0) 0%, rgba(253,180,45,0) 25%, rgba(253,180,45,1) 75%, rgba(253,180,45,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(253,180,45,0) 0%,rgba(253,180,45,0) 25%,rgba(253,180,45,1) 75%,rgba(253,180,45,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(253,180,45,0) 0%,rgba(253,180,45,0) 25%,rgba(253,180,45,1) 75%,rgba(253,180,45,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fdb42d', endColorstr='#fdb42d',GradientType=0 ); /* IE6-9 */

}

.carousel-caption img {
	width: 80%;
	max-width: 527px;
	height: auto;
	margin-bottom: 5px;
}
.loader-div {
	position:fixed;
	z-index: 999;
	top:0;
	left:0;
	right:0;
	bottom: 0;
	background: rgba(0,0,0,0.6);
	display: none;
}
.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #D81C57; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}